<template lang="pug">
div.marquee 
  span(
    :style='{ animationDuration: `${animationDuration}s`, animationIterationCount: animationRepeat }'
  ) {{ animationText }}
</template>

<script>
export default {
  name: 'VMarqueeText',
  props: {
    animationText: {
      type: String,
      default: '',
      required: true,
    },
    animationDuration: {
      type: Number,
      default: 15,
      required: false,
    },
    animationRepeat: {
      type: String || Number,
      default: 'infinate',
      required: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.marquee {
  width: auto;
  margin: 0 0.25rem;
  overflow: hidden;
  box-sizing: border-box;

  & > span {
    display: inline-block;
    width: max-content;
    padding-left: 100%;
    will-change: transform;
    animation: marquee 15s linear 0s infinite;
  }
}
@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}
</style>
