<template lang="pug">
div.v-scene
  div.loader-test
  div.v-scene__layout.v-scene__layout_decorations(
    :style='{ zIndex: isEmptyDecorationsLayout }'
  )
    img(
        v-for='item in scene.decorations'
        :class='{ "decoration__full-screen": item.params.fullScreen }'
        :key='item.id'
        :src='item.url'
      )
  div.v-scene__layout.v-scene__layout_background(
    :style='{ zIndex: isEmptyBackgroundLayout }'
  )
    div(v-for='item in scene.background' :key='item.id')
      img.v-scene__item(
        v-if='item.type === "img"'
        :width='defaultVideoWidth'
        :height='defaultVideoHeight'
        :src='item.url'
      )
      video.v-scene__item(
        v-if='item.type === "video"'
        autoplay
        muted
        loop
        crossOrigin='anonymous'
        :width='defaultVideoWidth'
        :height='defaultVideoHeight'
        ref='videobackground'
      )
        source(:src='item.url' type='video/mp4')
        object(:data='item.url')

  div.v-scene__layout.v-scene__layout_music(
    :style='{ zIndex: isEmptyMusicLayout }'
  )
    div(v-for='item in scene.music' :key='item.id')
      audio.v-scene__item(
        crossOrigin='anonymous'
        ref='backgroundmusic'
        :data-name='item.name'
      )
        source(:src='item.url' type='audio/mpeg')
        object(:data='item.url')

  div.v-scene__layout.v-scene__layout_video(
    :style='{ zIndex: isEmptyVideosLayout }'
  )
    div(v-for='item in scene.videos' :key='item.id')
      video.v-scene__item(
        crossOrigin='anonymous'
        :width='defaultVideoWidth'
        :height='defaultVideoHeight'
        :ref='`video${item.id}`'
        :key='item.id'
        :id='`${scene.id}-${item.id}`'
      )
        source(:src='item.url' type='video/mp4')
        object(:data='item.url')

  div.v-scene__layout.v-scene__layout_presentation(
    :style='{ zIndex: isEmptyPresentationsLayout }'
  )
    div(v-for='presentation in scene.presentations' :key='presentation.id')
      img.v-scene__item.v-scene__item_presentation(
        v-if='getSelectedPresentationId === presentation.id && !hasOrator'
        v-for='(image, index) in presentation.images'
        :width='defaultVideoWidth'
        :height='defaultVideoHeight'
        :key='image.id'
        :src='image.url'
        :class='{ hidden: index !== getCurrentPage - 1 }'
      )
      img.v-scene__item.v-scene__item_presentation(
        v-if='getSelectedPresentationId !== presentation.id && !hasOrator'
        :width='defaultVideoWidth'
        :height='defaultVideoHeight'
        :src='presentation.images[0].url'
      )
  div.v-scene__layout.v-scene__layout_cloud(
    v-if='scene && scene.interactive && !sceneOratorsList.length'
    v-model='scene.cloud'
    @change='mediaChangeDrag($event, "cloud")'
    handle='false'
  )
    div(
      v-for='item in scene.interactive'
      :key='item.id'
    )
      div.cloud(
        v-if='item.interactive_id === 10'
        :style='{ backgroundImage: `url(${item.json_settings.bg_image})` }'
      )
        word-cloud.proofix-interactive-cloud__cloud(:cloudInteractive='item')
      div.chart(v-if='item.interactive_id == 5')
        div.chart__title {{ titleInteractive }}
        interactive-chart(:interactive='item' @name-interactive='interactiveData')
  div.v-scene__layout.v-scene__layout_stream(
    :style='{ zIndex: isEmptyOratorLayout }'
  )
    div.v-scene__video(
      v-for='(container, index) in layouts'
      :key='modeScreen + container.name'
      :style='oratorStyle[container.name]'
    )
      //- video.v-scene__videos(
      //-   v-show='((orator[index] && orator[index].isVideoOn) || scene.screenSharing) && scene.modeScreen !== "SCREEN"'
      //-   autoplay
      //-   muted
      //-   ref='videos'
      //-   :class='{ "v-scene__videos_screen-sharing": scene.screenSharing && isLeftOrRightScreenMode }'
      //-   :data-orator='orator[index] ? orator[index].nameOrator : ""'
      //- )
      VVideoContainer.camera(
        :participant='getParticipant(orator[index])'
        :source='!isSharingScreen ? ["camera", "microphone"] : ["camera", "screen_share", "microphone", "screen_share_audio"]'
        v-if='((orator[index]))'
        v-show='scene.modeScreen !== "SCREEN"'
      )
      VVideoContainer.screenshare(
        :participant='getHostPartisipants[0]'
        :source='["screen_share", "microphone", "screen_share_audio"]'
        v-if='scene.screenSharing && !getParticipant(orator[index])'
      )
      div(
        v-if='(hasOrator && presentation && !orator[index]) || scene.modeScreen === "SCREEN"'
        v-show='!scene.screenSharing'
        ref='presentation'
      )
        img.v-scene__item(
          v-if='getSelectedPresentationId === presentation?.id && getSelectedPresentationImageUrl != "undefined"'
          :width='defaultVideoWidth'
          :height='defaultVideoHeight'
          :data-index='getCurrentPage - 1'
          :src='getSelectedPresentationImageUrl'
          :style='{ zIndex: scene.modeScreen === "SCREEN" ? 1 : 0 }'
        )
        img.v-scene__item(
          v-if='getSelectedPresentationId !== presentation?.id'
          :width='defaultVideoWidth'
          :height='defaultVideoHeight'
          :src='presentation.images[0]?.url'
        )
      div.v-scene__item(
        v-if='(hasOrator && interactive && !orator[index]) || scene.modeScreen === "SCREEN"'
        :width='defaultVideoWidth'
        :height='defaultVideoHeight'
        v-show='!scene.screenSharing'
        ref='interactive'
      )
        div(
          v-for='item in scene.interactive'
          :width='defaultVideoWidth'
          :height='defaultVideoHeight'
          :key='item.id'
        )
          div.cloud(
            v-if='item.interactive_id === 10'
            :style='{ backgroundImage: `url(${item.json_settings.bg_image})` }'
          )
            word-cloud.proofix-interactive-cloud__cloud(
              :cloudInteractive='item'
            )
          div.chart(v-if='item.interactive_id == 5')
            div.chart__title {{ titleInteractive }}
            interactive-chart(:interactive='item' @name-interactive='interactiveData')

      div.v-scene__label_container(
        :class='{ label_speakers: scene.layout.length === 1, label_two_speakers: scene.layout.length === 2, cropped_label: isTwoSpeakersWhithPresentation }'
      )
        transition(name='slide')
          label.v-scene__video_label(
            v-if='scene.isShowTitle && !showLabelForPresentation && orator[index]'
            :style='{ color: branding.primaryColor }'
            ref='labels'
          ) {{ orator[index].nameOrator.replace(/-[^-]*$/, '') }}
            div.after-slide-in
        transition(name='slide-second')
          label.v-scene__video_label.v-scene__video_title(
            v-if='scene.isShowTitle && !showLabelForPresentation && orator[index]'
            ref='jobtitle'
            :style='{ backgroundColor: branding.secondaryColor }'
          ) {{ orator[index].jobTitle }}
            div.after-slide-in
      //- div.v-scene__videos_poster(
      //-   v-show='hasOrator && orator[index] && !orator[index].isVideoOn'
      //- )
      //-   div.v-scene__images_container
      //-     div.v-scene__images(v-show='scene.modeScreen !== "SCREEN"')

  div.v-scene__label_container(:style='labelContainerStyle')
    transition(name='slide')
      label.v-scene__video_label.slidein-1(
        v-if='scene.isShowTitle && showLabelForPresentation && orator && orator[0]'
        :style='{ color: branding.primaryColor }'
        ref='labels'
      ) {{ orator[0].nameOrator.replace(/-[^-]*$/, '') }}
        div.after-slide-in
    transition(name='slide')
      label.v-scene__video_label.v-scene__video_title.slidein-2(
        v-if='scene.isShowTitle && showLabelForPresentation && orator[0]'
        ref='jobtitle'
        :style='{ backgroundColor: branding.secondaryColor }'
      ) {{ orator[0].jobTitle }}
        div.after-slide-in
  div.v-scene__banners(v-if='banner && !banner.options.ticker')
    div.v-scene__banner(:style='{ borderColor: branding.primaryColor }') {{ banner.description }}

  div.v-scene__tickers(v-if='banner && banner.options.ticker')
    div.v-scene__ticker(:style='{ borderColor: branding.primaryColor }')
      v-marquee-text(
        v-if='banner.options.ticker'
        :animationDuration='0.2 * banner.description.length'
        :animationText='banner.description'
      )
  v-scene-timer.v-scene__timer(
    v-if='timer'
    :timer='timer'
    ref="sceneTimer"
  )
</template>

<script>
import VMarqueeText from '@/components/v-marquee-text.vue'
import { mapGetters } from 'vuex'
import createNewId from '@/models/create-new-id'

import SCENE_LAYOUTS from '@/constants/scene-layouts'
import VIDEO from '@/constants/video'

// import Scene from '@/models/scene'
import VVideoContainer from '@/components/v-video-container.vue'
import VSceneTimer from '@/components/v-scene-timer.vue'
import WordCloud from '@/components/widgets/cloud/word-cloud'
import InteractiveChart from '@/components/widgets/chart/interactive-chart'

export default {
  name: 'VScene',
  components: {
    VMarqueeText,
    VSceneTimer,
    WordCloud,
    InteractiveChart,
    VVideoContainer,
  },
  props: {
    scene: {
      type: Object,
      default: () => {},
    },
    banner: {
      type: Object,
      default: null,
    },
    timer: {
      type: Object,
      default: null,
    },
    branding: {
      type: Object,
      default: null,
    },
    streamOratorWidth: {
      type: Number,
      default: null,
    },
    streamOratorHeight: {
      type: Number,
      default: null,
    },
    isList: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    fontSize: 20,
    titleInteractive: '',
    oratorStyle: {
      main: '',
      secondary: '',
      left: '',
      right: '',
      leftTop: '',
      rightTop: '',
      centerBot: '',
      leftBot: '',
      rightBot: '',
    },
    labelLeft: null,
    labelBottom: null,
    defaultVideoWidth: VIDEO.DEFAULT_WIDTH,
    defaultVideoHeight: VIDEO.DEFAULT_HEIGHT,
    // // chartData: {
    // //   data: {
    // //     labels: [],
    // //     datasets: [
    // //       {
    // //         data: [],
    // //         backgroundColor: '#000',
    // //         borderColor: 'transparent',
    // //         barThickness: 35,
    // //         datalabels: {
    // //           anchor: 'end',
    // //           align: 'end',
    // //           formatter: function (value, context) {
    // //             return (
    // //               context.chart.data.datasets[0].data[context.dataIndex] + ' %'
    // //             )
    // //           },
    // //         },
    // //       },
    // //     ],
    // //   },
    // // },
    // chartOption: {
    //   indexAxis: 'y',
    //   elements: {
    //     rectangle: {
    //       borderWidth: 2,
    //       borderSkipped: 'top',
    //     },
    //   },
    //   responsive: true,
    //   maintainAspectRatio: false,
    //   responsiveAnimationDuration: 500,
    //   legend: {
    //     display: false,
    //   },
    //   tooltips: {
    //     enabled: true,
    //   },
    //   scales: {
    //     xAxes: [
    //       {
    //         display: true,
    //         gridLines: {
    //           display: true,
    //           color: 'rgba(200, 200, 200, 0.2)',
    //           zeroLineColor: 'rgba(200, 200, 200, 0.2)',
    //         },
    //         scaleLabel: {
    //           display: true,
    //         },
    //         ticks: {
    //           fontColor: '#6e6b7b',
    //           beginAtZero: true,
    //           callback: function (value) {
    //             return value + '%'
    //           },
    //         },
    //       },
    //     ],
    //     yAxes: [
    //       {
    //         display: true,
    //         gridLines: {
    //           color: 'rgba(200, 200, 200, 0.2)',
    //           zeroLineColor: 'rgba(200, 200, 200, 0.2)',
    //         },
    //         scaleLabel: {
    //           display: true,
    //           // labelString: 'Посетители',
    //         },
    //         ticks: {
    //           min: 0,
    //           fontColor: '#6e6b7b',
    //           callback: function (value) {
    //             return value.split(' ')
    //           },
    //         },
    //       },
    //     ],
    //   },
    // },

    // myStyles: {
    //   height: '100%',
    //   width: '100%',
    //   position: 'relative',
    // },
  }),
  computed: {
    ...mapGetters('studio', ['getOrators']),
    ...mapGetters('participant', [
      'getParticipants',
      'getLocalParticipant',
      'getHostPartisipants',
    ]),
    ...mapGetters('track', ['participantScreenShareMuted']),
    getCurrentPage() {
      return this.scene.presentations?.[0]?.currentPage
    },
    getSelectedPresentationImageUrl() {
      const currentImage =
        this.scene.presentations?.[0]?.images[this.getCurrentPage - 1]
      return decodeURIComponent(currentImage?.url)
    },
    getSelectedPresentationId() {
      return this.scene.presentations?.[0]?.id
    },
    modeScreen() {
      return this.scene?.modeScreen
    },
    hasOrator() {
      return Boolean(this.sceneOratorsList.length)
    },
    orator() {
      return this.sceneOratorsList
    },
    presentation() {
      return this.scene?.presentations?.[0]
    },
    interactive() {
      return this.scene?.interactive.length
    },
    video() {
      return this.scene?.videos?.[0]
    },
    isSharingScreen() {
      return this.participantScreenShareMuted(this.getHostPartisipants[0])
    },
    layouts() {
      return this.scene?.layout
    },
    isEmptyMusicLayout() {
      return this.scene?.music?.length === 0 ? -1 : 0
    },
    isEmptyVideosLayout() {
      return this.scene?.videos?.length === 0 ? -1 : 0
    },
    isEmptyPresentationsLayout() {
      return this.scene?.presentations?.length === 0 ? -1 : 1
    },
    isEmptyBackgroundLayout() {
      return this.scene?.background?.length === 0 ? -1 : 0
    },
    isEmptyOratorLayout() {
      return this.hasOrator || this.scene.screenSharing || this.presentation
        ? 1
        : -1
    },
    isEmptyDecorationsLayout() {
      return this.scene?.decorations?.length === 0 ? -1 : 2
    },
    isLeftOrRightScreenMode() {
      return (
        this.modeScreen === 'LEFT_SCREEN' || this.modeScreen === 'RIGHT_SCREEN'
      )
    },
    sceneOratorsList() {
      return this.scene?.orator || []
    },
    labelContainerStyle() {
      let style = {}
      if (this.labelLeft !== null) {
        style.left = `${this.labelLeft}%`
        style.bottom = `${this.labelBottom}% !important`
      }
      style.fontSize = '1.3vw'
      return style
    },
    showLabelForPresentation() {
      return (
        (this.presentation &&
          (this.modeScreen === 'LEFT_SCREEN' ||
            this.modeScreen === 'RIGHT_SCREEN' ||
            this.modeScreen === 'PICTURE_IN_PICTURE')) ||
        (this.interactive &&
          (this.modeScreen === 'LEFT_SCREEN' ||
            this.modeScreen === 'RIGHT_SCREEN' ||
            this.modeScreen === 'PICTURE_IN_PICTURE'))
      )
    },
    computedScene() {
      return this.scene ? JSON.parse(JSON.stringify(this.scene)) : this.scene
    },
    isTwoSpeakersWhithPresentation() {
      return (
        this.modeScreen === 'LEFT_SCREEN_TWO_SPEAKERS' ||
        this.modeScreen === 'RIGHT_SCREEN_TWO_SPEAKERS'
      )
    },
    musicVolume() {
      return this.scene?.music[0]?.volume ?? 1
    },
  },
  watch: {
    computedScene: {
      deep: true,
      handler(newVal, oldVal) {
        if (
          newVal === oldVal ||
          (newVal?.id === oldVal?.id &&
            newVal?.isShowTitle !== oldVal?.isShowTitle)
        ) {
          return
        }

        // if (this.isEmptyVideosLayout) {
        //   this.$store.commit('UPDATE_SCENE_VIDEO_STATUS', false)
        // } else {
        //   this.$store.commit('UPDATE_SCENE_VIDEO_STATUS', true)
        // }

        if (
          this.scene?.background &&
          this.scene?.background.length > 0 &&
          this.scene?.background[0].type === 'video' &&
          newVal.background[0]?.id !== oldVal.background[0]?.id
        ) {
          if (
            this.$refs.videobackground &&
            this.$refs.videobackground.length > 0
          ) {
            this.$refs.videobackground[0].pause()
            this.$refs.videobackground[0].load()
            this.$refs.videobackground[0].play()
          }
        }

        setTimeout(() => {
          this.playStreamToVideo()
        }, 0)
      },
    },
    'getSelectedScene.presentations.length'(newVal) {
      if (newVal === 0) {
        this.updateLayout()
      }
    },
    modeScreen() {
      this.updateLayout()
    },
    isSharingScreen() {
      this.updateLayout()
    },
    musicVolume() {
      const musicEl = this.$refs.backgroundmusic?.[0]
      if (musicEl) musicEl.volume = this.musicVolume / 100
    },
  },
  async mounted() {
    this.updateLayout()
  },
  methods: {
    getParticipant(orator) {
      if (orator) {
        const identity = createNewId(orator.id, this.$route.query.workspace)
        if (this.getLocalParticipant?.[1].identity === identity) {
          return this.getLocalParticipant
        }
        const remoteParticipant = this.getParticipants.find((participant) => {
          return participant[1].identity === identity
        })
        return remoteParticipant
      }
      return undefined
    },
    updateLayout() {
      // let data = {}
      console.log('update scene')
      if (this.isSharingScreen) {
        // data = {
        //   layout: SCENE_LAYOUTS[this.scene.modeScreen],
        // }
      } else {
        switch (this.sceneOratorsList.length) {
          case 2:
            // this.previousModeScreen = this.scene.modeScreen
            // data = {
            //   layout: SCENE_LAYOUTS.SIDE_BY_SIDE,
            //   modeScreen: 'SIDE_BY_SIDE',
            // }
            break
          case 3:
            // data = {
            //   layout: SCENE_LAYOUTS.THREE_SPEAKERS,
            //   modeScreen: 'THREE_SPEAKERS',
            // }
            break
          default:
            if (this.sceneOratorsList.length <= 1) {
              if (this.presentation) {
                // data = {
                //   layout: SCENE_LAYOUTS[this.scene.modeScreen],
                // }
              } else {
                // data = {
                //   layout: SCENE_LAYOUTS.SIMPLE,
                //   modeScreen: 'SIMPLE',
                // }
              }
            } else if (this.sceneOratorsList.length > 3) {
              // data = {
              //   layout: SCENE_LAYOUTS.FOUR_PARTS,
              //   modeScreen: 'FOUR_PARTS',
              // }
            }
        }
      }

      // this.updateScene({
      //   sceneId: this.scene.id,
      //   data: data,
      // })

      SCENE_LAYOUTS[this.modeScreen].forEach((container) => {
        this.getOratorStyle(container)
      })
      this.playStreamToVideo()
    },
    onRemove() {
      this.$emit('updateSpeakerOnScene')
    },
    onAdd() {
      this.$emit('updateSpeakerOnScene')
    },
    playStreamToVideo() {
      // создаем массив из стримов оратора (с веб-камеры + шаринг)
      const getSharingStream =
        this.$parent.$refs.remoteScreenSharing.querySelector('video')?.srcObject
      console.log('getSharingStream: ', getSharingStream)
      // let oratorStreams = null

      // if (this.sceneOratorsList.length <= 1) {
      //   if (
      //     this.getLocalOrator &&
      //     +this.getLocalOrator.id === +this.sceneOratorsList?.[0]?.id
      //   ) {
      //     oratorStreams = [
      //       this.hasOrator ? this.getLocalOrator.stream : false,
      //       // this.presentation ? false : getSharingStream,
      //       this.isSharingScreen
      //         ? getSharingStream
      //         : this.presentation
      //         ? false
      //         : getSharingStream,
      //     ]
      //   } else {
      //     oratorStreams = [
      //       this.sceneOratorsList?.[0]?.stream,
      //       // this.presentation ? false : getSharingStream,
      //       this.isSharingScreen
      //         ? getSharingStream
      //         : this.presentation
      //         ? false
      //         : getSharingStream,
      //     ]
      //   }
      // }

      // если есть сетка
      if (this.$refs.videos) {
        ;[...this.$refs.videos].forEach((element, index) => {
          const orator = this.getOrators.find(
            (orator) => orator.name == element.dataset.orator
          )
          // let isDrawing = false
          if (this.presentation) {
            // isDrawing = true
            if (index === 0) {
              this.updateSrcVideo(element, orator?.stream)
            }
          }
          if (this.isSharingScreen) {
            // isDrawing = true
            // if (!this.hasOrator) {
            this.updateSrcVideo(element, getSharingStream)
            //   } else {
            //     const stream = oratorStreams[index]
            //     // const stream =
            //     //   this.modeScreen === 'PICTURE_IN_PICTURE'
            //     //     ? oratorStreams[oratorStreams.length - 1 - index]
            //     //     : oratorStreams[index]
            //     if (stream) {
            //       this.updateSrcVideo(element, stream)
            //     }
            // }
          }
          // if (!isDrawing) {
          if (orator && orator.stream) {
            this.updateSrcVideo(element, orator?.stream)
          }
          // const stream = this.orator[index]?.stream
          // if (stream && this.orator[index]?.isVideoOn) {
          //   this.updateSrcVideo(element, stream)
          // }
          // }

          // if (this.isSharingScreen && !this.presentation && !this.hasOrator) {
          //   element.srcObject = getSharingStream;
          // } else if (
          //   this.isSharingScreen &&
          //   !this.presentation &&
          //   this.hasOrator
          // ) {
          //   const stream =
          //     this.modeScreen === "PICTURE_IN_PICTURE"
          //       ? oratorStreams[oratorStreams.length - 1 - index]
          //       : oratorStreams[index];
          //   if (stream) {
          //     element.srcObject = stream;
          //   }
          // } else if (this.presentation) {
          //   if (index === 0) {
          //     element.srcObject = oratorStreams[0];
          //   }
          // } else {
          //   const stream = this.orator[index]?.stream;
          //   if (stream && this.orator[index]?.isVideoOn) {
          //     element.srcObject = stream;
          //     element.src = null;
          //   }
          // }
        })
      }
    },
    getPropertyName(prop) {
      if (prop === 'zIndex') {
        return 'z-index'
      } else {
        return prop
      }
    },
    getOratorStyle(layout) {
      this.oratorStyle[layout.name] = ''

      if (this.presentation) {
        if (
          this.modeScreen === 'LEFT_SCREEN' ||
          this.modeScreen === 'RIGHT_SCREEN'
        ) {
          let oratorStyle = {}
          let presentationStyle = {}

          oratorStyle.width = 100
          oratorStyle.height = 100

          let trueOratorWidth = oratorStyle.width

          if (this.streamOratorWidth && this.streamOratorHeight) {
            oratorStyle.width =
              (this.streamOratorWidth / this.defaultVideoWidth) * 100
            oratorStyle.height =
              (this.streamOratorHeight / this.defaultVideoHeight) * 100

            trueOratorWidth = this.streamOratorWidth
          } else {
            const orator = this.getOrators.find(
              (orator) => orator.name == this.sceneOratorsList[0].name
            )
            const oratorVideoSettings =
              orator?.stream?.getVideoTracks()[0].getSettings() || null

            if (oratorVideoSettings) {
              if (oratorVideoSettings.width) {
                oratorStyle.width =
                  (oratorVideoSettings.width / this.defaultVideoWidth) * 100
                trueOratorWidth = oratorStyle.width
              }
              if (oratorVideoSettings.height) {
                oratorStyle.height =
                  (oratorVideoSettings.height / this.defaultVideoHeight) * 100
              }
            }
          }

          const oratorAspectRatio = oratorStyle.width / oratorStyle.height

          const firstSlide = this.presentation.images[0].url
          const presentationAspectRatio =
            firstSlide.naturalWidth / firstSlide.naturalHeight

          if (oratorStyle.width > 50) {
            presentationStyle.width = 64
          } else {
            presentationStyle.width = 64
          }

          let newSlideHeight = firstSlide.naturalHeight
          if (firstSlide.naturalWidth / firstSlide.naturalHeight !== 16 / 9) {
            newSlideHeight =
              (firstSlide.naturalHeight / 100) * presentationStyle.width
          }

          if (newSlideHeight > this.defaultVideoHeight) {
            presentationStyle.height = 100
            presentationStyle.width =
              presentationStyle.height / presentationAspectRatio - 100
          } else if (newSlideHeight < this.defaultVideoHeight) {
            presentationStyle.height =
              (newSlideHeight / this.defaultVideoHeight) * 100
          } else {
            presentationStyle.height = presentationStyle.width
          }

          if (presentationStyle.height) {
            oratorStyle.height = presentationStyle.height
            oratorStyle.width = oratorStyle.height * oratorAspectRatio

            trueOratorWidth = oratorStyle.width
          }

          if (layout.name === 'left') {
            if (this.modeScreen === 'LEFT_SCREEN') {
              if (trueOratorWidth > 50) {
                oratorStyle.right = 0 - (trueOratorWidth / 100) * 20
              } else {
                oratorStyle.right = 0
              }

              this.labelLeft = presentationStyle.width
            } else if (this.modeScreen === 'RIGHT_SCREEN') {
              if (trueOratorWidth > 50) {
                oratorStyle.left = 0 - (trueOratorWidth / 100) * 20
              } else {
                oratorStyle.left = 0
              }

              this.labelLeft = 0
            }

            oratorStyle.top = (100 - oratorStyle.height) / 2

            Object.keys(oratorStyle).forEach((property) => {
              this.oratorStyle[layout.name] +=
                this.getPropertyName(property) +
                ':' +
                oratorStyle[property] +
                '%;'
            })
          } else if (layout.name === 'right') {
            if (this.modeScreen === 'LEFT_SCREEN') {
              presentationStyle.left = 0
            } else if (this.modeScreen === 'RIGHT_SCREEN') {
              presentationStyle.right = 0
            }

            presentationStyle.top = (100 - presentationStyle.height) / 2
            this.labelBottom = presentationStyle.top + 7

            Object.keys(presentationStyle).forEach((property) => {
              this.oratorStyle[layout.name] +=
                this.getPropertyName(property) +
                ':' +
                presentationStyle[property] +
                '%;'
            })
          }
        } else {
          if (
            (this.modeScreen === 'SCREEN' && layout.name === 'secondary') ||
            (this.modeScreen === 'PICTURE_IN_PICTURE' &&
              layout.name === 'secondary')
          ) {
            const firstSlide = this.presentation.images[0].url
            const presentationAspectRatio =
              firstSlide.naturalWidth / firstSlide.naturalHeight

            let presentationStyle = {}

            let newSlideHeight = firstSlide.naturalHeight
            if (newSlideHeight > this.defaultVideoHeight) {
              presentationStyle.height = 100
              presentationStyle.width =
                presentationStyle.height / presentationAspectRatio - 100
              if (presentationStyle.width < 0) {
                presentationStyle.width =
                  presentationStyle.height / presentationAspectRatio
              }
            } else if (newSlideHeight < this.defaultVideoHeight) {
              presentationStyle.height =
                (newSlideHeight / this.defaultVideoHeight) * 100
            } else {
              presentationStyle.height = presentationStyle.width
            }

            presentationStyle.top = 0
            presentationStyle.left = (100 - presentationStyle.width) / 2

            if (this.modeScreen === 'PICTURE_IN_PICTURE') {
              this.labelLeft = 0
              this.labelBottom = 7
            } else {
              this.labelLeft = null
              this.labelBottom = null
            }

            Object.keys(presentationStyle).forEach((property) => {
              this.oratorStyle[layout.name] +=
                this.getPropertyName(property) +
                ':' +
                presentationStyle[property] +
                '%;'
            })
          } else {
            for (const property in layout) {
              if (property === 'name') continue
              Object.keys(layout[property]).forEach((key) => {
                this.oratorStyle[layout.name] +=
                  this.getPropertyName(key) + ':' + layout[property][key]

                this.oratorStyle[layout.name] +=
                  property === 'position' || property === 'size' ? '%;' : ';'
              })
            }
          }
        }
      } else {
        this.labelLeft = null
        this.labelBottom = null

        for (const property in layout) {
          if (property === 'name') continue
          Object.keys(layout[property]).forEach((key) => {
            this.oratorStyle[layout.name] +=
              this.getPropertyName(key) + ':' + layout[property][key]

            this.oratorStyle[layout.name] +=
              property === 'position' || property === 'size' ? '%;' : ';'
          })
        }
      }
    },
    updateFontSize() {
      if (this.$refs.currentScene) {
        this.fontSize = this.$refs.currentScene?.clientWidth / (1280 / 20)
      } else {
        this.fontSize = 20
      }
    },
    updateSrcVideo(videoElem, stream) {
      const oldVideoTrack = videoElem.srcObject?.getVideoTracks()[0]
      if (oldVideoTrack !== stream?.getVideoTracks()?.[0]) {
        // проверка видеотрека помогает избежать лишнего мерцания в кейсах когда он не изменился
        videoElem.srcObject = stream
        videoElem.src = null
      }
    },
    interactiveData(e) {
      this.titleInteractive = e
    },
    // tickTimer({ minutes, seconds }) {
    //   this.$emit('tick-timer', { minutes, seconds })
    // },
  },
}
</script>

<style lang="scss">
.v-scene {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: #000;
  overflow: hidden;

  &.list {
    user-select: none;
    pointer-events: none;
    border-radius: 4px;
  }

  &__images_container {
    height: 100%;
    display: flex;
    align-items: center;
  }

  &__images {
    width: 100%;
    height: 100%;
    background: url('~@/images/account_circle.png') center center no-repeat;
    background-size: contain;
  }

  &__layout {
    box-sizing: content-box;
    position: absolute;
    top: 0;
    left: 0;
    //width: calc(100% - 4px);
    //height: calc(100% - 4px);
    width: 100%;
    height: 100%;
    //border: 2px solid #e5e5e5;

    &.start_move {
      z-index: 999 !important;
      border: 2px solid #00cfe8;
      border-radius: $border-radius;
      background-color: rgba(0, 207, 232, 0.2);
    }

    &_background {
      overflow: hidden;
      z-index: 0;
    }

    &_presentation {
      overflow: hidden;
      position: relative;
      width: 100%;
    }

    &_video {
      overflow: hidden;
      z-index: 1;
    }

    &_stream {
      overflow: hidden;
      z-index: 2;
    }

    &_decorations {
      overflow: hidden;
      user-select: none;
      pointer-events: none;
      z-index: 3;

      img {
        position: absolute;
        right: 0;
        height: 10%;

        &.decoration__full-screen {
          height: 100%;
          width: 100%;
          object-fit: contain;
          object-position: top left;
        }
      }
    }
  }

  &__videos {
    width: 100%;
    height: 100%;

    &_poster {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
    }

    &_screen-sharing {
      width: auto;
    }
  }

  &__label_container {
    display: flex;
    flex-direction: column;
    height: fit-content;
    width: fit-content;
    position: absolute;
    // top: 55%;
    bottom: 16%;
    overflow: hidden;
    &.cropped_label {
      left: 7%;
    }
    .label_speakers {
      // top: 75% !important;
      // bottom: 7% !important;
    }

    .label_two_speakers {
      // top: 50% !important;
      // bottom: 32% !important;
    }
  }

  &__video {
    position: absolute;
    width: 100%;
    height: 100%;
    // border-radius: $border-radius;

    &_label {
      background-color: #ffffff;
      color: #0079c2;
      z-index: 9;
      padding: 0.75em 0.5em;
      width: fit-content;
      position: relative;

      .after-slide-in {
        width: 0%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: inherit;
      }
    }

    &_title {
      background-color: #0079c2;
      color: #ffffff;
      z-index: 9;
      padding: 0.75em 0.5em;
      width: fit-content;
    }
  }

  &__item {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    //background: #fff;

    &.hidden {
      display: none;
    }

    &_presentation {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: contain;
    }
    &-img {
      width: 100%;
      height: 100%;
    }
  }

  &__banners {
    width: calc(100% - 160px);
    position: absolute;
    bottom: 80px;
    left: 80px;
    z-index: 9;
  }

  &__banner {
    width: 100%;
    padding: 40px;
    border-left: 40px solid #04c300;
    background-color: #323233;
    color: #fff;
    margin-top: 40px;
    font-size: 40px;
  }

  &__tickers {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 9;
  }

  &__ticker {
    width: 100%;
    padding: 40px 0;
    background-color: #323233;
    color: #fff;
    font-size: 50px;
  }

  &__timer {
    z-index: 9;
    background-color: rgba(50, 50, 51, 0.9);
  }

  .after-slide-in {
    animation-duration: 1s;
    animation-name: after-slidein;
  }

  @keyframes after-slidein {
    from {
      width: 100%;
    }

    to {
      width: 0;
    }

    50% {
      width: 100%;
    }
  }

  .slide-enter-active,
  .slide-leave-active,
  .slide-second-enter-active,
  .slide-second-leave-active {
    transition-duration: 0.5s;
  }

  .slide-enter,
  .slide-leave-to,
  .slide-second-enter,
  .slide-second-leave-to {
    transform: translateX(-100%);
  }

  .slide-second-enter-active,
  .slide-second-leave-active {
    transition-delay: 300ms;
  }
}
.cloud {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #fff;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.chart {
  position: absolute;
  height: 100%;
  width: 100%;
  //height: calc(100% - 112px);
  &__title {
    background: #fff;
    //padding: 5px;
    font-size: 28px;
    text-align: center;
    color: #333;
    max-height: 112px;
  }
}
.proofix-interactive__chart {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #fff;
}
.loader-test {
  width: 100px;
  height: 100px;
  background-color: red;
  display: block;
  position: absolute;
  z-index: 10000;
  animation: rotate 1s linear infinite;
  left: 0%;
  top: 0%;
  // margin-left: -50px;
  // margin-right: -50px;
}
@keyframes rotate {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.02;
  }
  100% {
    opacity: 0;
  }
}
</style>
