<template lang="pug">
div.proofix-word-cloud(
  :style='progressVisible && progress ? { filter: "blur(8px)", opacity: 0.3, pointerEvents: "none", transform: "scale(0.7,0.7)" } : {}'
)
  vue-word-cloud.word-cloud(
    style='{ height: 100%; width: 98%; margin: auto; position: relative; }'
    :animation-duration='animationDuration'
    :animation-easing='animationEasing'
    :animation-overlap='animationOverlap'
    :color='color'
    :enter-animation='enterAnimation'
    :font-family='fontFamily'
    :font-size-ratio='fontSizeRatio'
    :leave-animation='leaveAnimation'
    :load-font='loadFont'
    :progress.sync='progress'
    :rotation='rotation'
    :spacing='spacing'
    :words='words'
  )
</template>
<script>
const svgNS = 'http://www.w3.org/2000/svg'
import VueWordCloud from 'vuewordcloud'
import Chance from 'chance'
import FontFaceObserver from 'fontfaceobserver'
import axios from 'axios'
import resourceMain from '/config'
import api from '@/constants/api'
import { mapMutations } from 'vuex'
var chance = new Chance()
// import { differenceBy } from 'lodash'
// style='height: 480pxwidth: 640px margin: auto max-width: 100%'
export default {
  name: 'WordCloud',
  components: {
    [VueWordCloud.name]: VueWordCloud,
  },
  props: {
    cloudInteractive: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      words: [],
      interval: '',
      animation: ['fadeIn', 'fadeOut'],
      animationDurationValueIndex: 2,
      animationDurationValues: [0, 1000, 5000, 10000],
      animationEasing: 'ease',
      animationEasingValues: [
        'ease',
        'linear',
        'ease-in',
        'ease-out',
        'ease-in-out',
        'cubic-bezier(0.1,0.7,1.0,0.1)',
      ],
      animationItems: [
        {
          text: 'fade',
          value: ['fadeIn', 'fadeOut'],
        },
      ],
      animationOverlapValueIndex: 1,
      animationOverlapValues: [0, 1 / 5, 1 / 2, 1],
      colorItemIndex: undefined,
      colorItems: [this.cloudInteractive.json_settings.words_colors],
      drawer: true,
      fontFamily: undefined,
      fontFamilyValues: ['Roboto'],
      fontSizeRatioValueIndex: 0,
      fontSizeRatioValues: [0, 1 / 20, 1 / 5, 1 / 2, 1],
      progress: undefined,
      progressVisible: false,
      rotationItemIndex: 0,
      rotationItems: [
        {
          value: 0,
          svg: (function () {
            var div = document.createElement('div')
            div.appendChild(
              (function () {
                var svg = document.createElementNS(svgNS, 'svg')
                svg.setAttribute('viewBox', '0 0 12 12')
                svg.appendChild(
                  (function () {
                    var path = document.createElementNS(svgNS, 'path')
                    path.setAttribute('d', 'M0 7 L0 5 L12 5 L12 7 Z')
                    return path
                  })()
                )
                return svg
              })()
            )
            return URL.createObjectURL(new Blob([div.innerHTML]))
          })(),
        },
        {
          value: 7 / 8,
          svg: (function () {
            var div = document.createElement('div')
            div.appendChild(
              (function () {
                var svg = document.createElementNS(svgNS, 'svg')
                svg.setAttribute('viewBox', '0 0 12 12')
                svg.appendChild(
                  (function () {
                    var path = document.createElementNS(svgNS, 'path')
                    path.setAttribute('d', 'M0 7 L0 5 L12 5 L12 7 Z')
                    path.setAttribute('transform', 'rotate(315 6 6)')
                    return path
                  })()
                )
                return svg
              })()
            )
            return URL.createObjectURL(new Blob([div.innerHTML]))
          })(),
        },
        {
          value: function (word) {
            var chance = new Chance(word[0])
            return chance.pickone([0, 3 / 4])
          },
          svg: (function () {
            var div = document.createElement('div')
            div.appendChild(
              (function () {
                var svg = document.createElementNS(svgNS, 'svg')
                svg.setAttribute('viewBox', '0 0 12 12')
                svg.appendChild(
                  (function () {
                    var path = document.createElementNS(svgNS, 'path')
                    path.setAttribute('d', 'M0 7 L0 5 L12 5 L12 7 Z')
                    return path
                  })()
                )
                svg.appendChild(
                  (function () {
                    var path = document.createElementNS(svgNS, 'path')
                    path.setAttribute('d', 'M0 7 L0 5 L12 5 L12 7 Z')
                    path.setAttribute('transform', 'rotate(90 6 6)')
                    return path
                  })()
                )
                return svg
              })()
            )
            return URL.createObjectURL(new Blob([div.innerHTML]))
          })(),
        },
        {
          value: function (word) {
            var chance = new Chance(word[0])
            return chance.pickone([0, 1 / 8, 3 / 4, 7 / 8])
          },
          svg: (function () {
            var div = document.createElement('div')
            div.appendChild(
              (function () {
                var svg = document.createElementNS(svgNS, 'svg')
                svg.setAttribute('viewBox', '0 0 12 12')
                svg.appendChild(
                  (function () {
                    var path = document.createElementNS(svgNS, 'path')
                    path.setAttribute('d', 'M0 7 L0 5 L12 5 L12 7 Z')
                    return path
                  })()
                )
                svg.appendChild(
                  (function () {
                    var path = document.createElementNS(svgNS, 'path')
                    path.setAttribute('d', 'M0 7 L0 5 L12 5 L12 7 Z')
                    path.setAttribute('transform', 'rotate(45 6 6)')
                    return path
                  })()
                )
                svg.appendChild(
                  (function () {
                    var path = document.createElementNS(svgNS, 'path')
                    path.setAttribute('d', 'M0 7 L0 5 L12 5 L12 7 Z')
                    path.setAttribute('transform', 'rotate(90 6 6)')
                    return path
                  })()
                )
                svg.appendChild(
                  (function () {
                    var path = document.createElementNS(svgNS, 'path')
                    path.setAttribute('d', 'M0 7 L0 5 L12 5 L12 7 Z')
                    path.setAttribute('transform', 'rotate(315 6 6)')
                    return path
                  })()
                )
                return svg
              })()
            )
            return URL.createObjectURL(new Blob([div.innerHTML]))
          })(),
        },
        {
          value: function (word) {
            var chance = new Chance(word[0])
            return chance.random()
          },
          svg: (function () {
            var div = document.createElement('div')
            div.appendChild(
              (function () {
                var svg = document.createElementNS(svgNS, 'svg')
                svg.setAttribute('viewBox', '0 0 2 2')
                svg.appendChild(
                  (function () {
                    var circle = document.createElementNS(svgNS, 'circle')
                    circle.setAttribute('cx', 1)
                    circle.setAttribute('cy', 1)
                    circle.setAttribute('r', 1)
                    return circle
                  })()
                )
                return svg
              })()
            )
            return URL.createObjectURL(new Blob([div.innerHTML]))
          })(),
        },
      ],
      snackbarText: '',
      snackbarVisible: false,
      spacingValueIndex: 1,
      spacingValues: [0, 1 / 4, 1 / 2, 1, 2],
      wordsText: [],
      wordsItems: [],
    }
  },

  computed: {
    animationDuration: function () {
      return this.animationDurationValues[this.animationDurationValueIndex]
    },

    animationOverlap: function () {
      return this.animationOverlapValues[this.animationOverlapValueIndex]
    },

    color: function () {
      var colors = this.colorItems[this.colorItemIndex]
      return function () {
        return chance.pickone(colors)
      }
    },

    enterAnimation: function () {
      return ['animated', this.animation[0]].join(' ')
    },

    fontSizeRatio: function () {
      return this.fontSizeRatioValues[this.fontSizeRatioValueIndex]
    },

    leaveAnimation: function () {
      return ['animated', this.animation[1]].join(' ')
    },

    rotation: function () {
      var item = this.rotationItems[this.rotationItemIndex]
      return item.value
    },

    spacing: function () {
      return this.spacingValues[this.spacingValueIndex]
    },
  },

  watch: {
    progress: function (currentProgress, previousProgress) {
      if (previousProgress) {
        this.progressVisible = false
      }
    },
  },

  created: function () {
    this.animation = chance.pickone(this.animationItems).value
    this.animationEasing = chance.pickone(this.animationEasingValues)
    this.colorItemIndex = chance.integer({
      min: 0,
      max: this.colorItems.length - 1,
    })
    this.fontFamily = chance.pickone(this.fontFamilyValues)
  },

  mounted() {
    this.getWords()
    clearInterval(this.interval)
    this.interval = setInterval(() => {
      this.getWords()
    }, 10000)
  },
  destroyed() {
    clearInterval(this.interval)
  },

  methods: {
    ...mapMutations('interactives', ['updateEventId']),
    reverseMessage: function () {
      this.wordsText = JSON.parse(
        '[["qwe" + Math.random() + "",1],["qwe3",1],["ovge",1]]'
      )
    },
    getWords() {
      try {
        axios
          .get(
            `${resourceMain.API.main.root}${api.GET_CLOUD_WORD.replace(
              ':room_id',
              this.cloudInteractive.room_id
            )}`,
            {
              headers: {
                Authorization:
                  'Bearer Be39JWWga+P2okT3h312IL3FzzLAavENlgbeYAoUskE=',
              },
            }
          )
          .then((res) => {
            console.log(res.data)
            this.words = res.data.data
          })
      } catch (e) {
        console.log('Ошибка обновления облака слов')
      }
    },
    loadFont: function (fontFamily, fontStyle, fontWeight, text) {
      return new FontFaceObserver(fontFamily, {
        style: fontStyle,
        weight: fontWeight,
      }).load(text)
    },

    onWordClick: function (word) {
      this.snackbarVisible = true
      this.snackbarText = word[0]
    },
  },
}
</script>

<style lang="scss">
// @import 'animate.css';
.proofix-word-cloud {
  width: 100%;
  margin: auto;
  overflow: hidden;
  height: 100%;
}
.word-cloud span {
  transition: color 4s;
}
</style>
