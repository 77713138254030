/**
 * @constant
 * @type {Layout[]}
 * Пресеты компоновок стромов
 *
 * @typedef Position
 * @type {object}
 * @property {number=} top
 * @property {number=} bottom
 * @property {number=} left
 * @property {number=} right
 *
 * @typedef Size
 * @type {object}
 * @property {number} height
 * @property {number} width
 *
 * @typedef Background
 * @type {object}
 * @property {string} background
 *
 * @typedef Layout
 * @type {object}
 * @property {string} name
 * @property {Position} position
 * @property {Size} size
 * @property {Background} background
 *
 */
export const SCENE_LAYOUTS = {
  SIMPLE: [
    {
      name: 'main',
      position: {
        top: 0,
        left: 0,
      },
      size: {
        height: 100,
        width: 100,
      },
      font: {
        fontSize: '2vw',
      },
    },
  ],
  SCREEN: [
    {
      name: 'main',
      position: {
        top: 0,
        left: 0,
      },
      size: {
        height: 0,
        width: 0,
      },
      font: {
        fontSize: '1.3vw',
      },
    },
    {
      name: 'secondary',
      position: {
        top: 0,
        left: 0,
      },
      size: {
        height: 100,
        width: 100,
      },
      font: {
        fontSize: '1.3vw',
      },
    },
  ],
  PICTURE_IN_PICTURE: [
    {
      name: 'main',
      position: {
        top: 70,
        left: 77,
      },
      size: {
        height: 20,
        width: 20,
      },
      z: {
        zIndex: 1,
      },
      font: {
        fontSize: '1.3vw',
      },
    },
    {
      name: 'secondary',
      position: {
        top: 0,
        left: 0,
      },
      size: {
        height: 100,
        width: 100,
      },
      font: {
        fontSize: '1.3vw',
      },
    },
  ],
  LEFT_SCREEN: [
    {
      name: 'left',
      position: {
        top: 15,
        left: 50,
      },
      size: {
        height: 70,
        width: 62,
      },
      font: {
        fontSize: '1.3vw',
      },
    },
    {
      name: 'right',
      position: {
        top: 15,
        left: 0,
      },
      size: {
        height: 70,
        width: 62,
      },
      font: {
        fontSize: '1.3vw',
      },
    },
  ],
  LEFT_SCREEN_TWO_SPEAKERS: [
    {
      name: 'rightTop',
      position: {
        top: 15,
        left: 67.5,
      },
      size: {
        height: 35,
        width: 35,
      },
      font: {
        fontSize: '1.3vw',
      },
    },
    {
      name: 'rightBot',
      position: {
        top: 50,
        left: 67.5,
      },
      size: {
        height: 35,
        width: 35,
      },
      font: {
        fontSize: '1.3vw',
      },
    },
    {
      name: 'left',
      position: {
        top: 15,
        left: 0,
      },
      size: {
        height: 70,
        width: 70,
      },
      font: {
        fontSize: '1.3vw',
      },
    },
  ],
  LEFT_SCREEN_THREE_SPEAKERS: [
    {
      name: 'rightTop',
      position: {
        top: 12.5,
        left: 75,
      },
      size: {
        height: 25,
        width: 25,
      },
      font: {
        fontSize: '1.3vw',
      },
    },
    {
      name: 'rightCenter',
      position: {
        top: 37.5,
        left: 75,
      },
      size: {
        height: 25,
        width: 25,
      },
      font: {
        fontSize: '1.3vw',
      },
    },
    {
      name: 'rightBot',
      position: {
        top: 62.5,
        left: 75,
      },
      size: {
        height: 25,
        width: 25,
      },
      font: {
        fontSize: '1.3vw',
      },
    },
    {
      name: 'left',
      position: {
        top: 12.5,
        left: 0,
      },
      size: {
        height: 75,
        width: 75,
      },
      font: {
        fontSize: '1.3vw',
      },
    },
  ],
  LEFT_SCREEN_FOUR_SPEAKERS: [
    {
      name: 'rightTop-1',
      position: {
        top: 0,
        left: 75,
      },
      size: {
        height: 25,
        width: 25,
      },
      font: {
        fontSize: '1.3vw',
      },
    },
    {
      name: 'rightTop-2',
      position: {
        top: 25,
        left: 75,
      },
      size: {
        height: 25,
        width: 25,
      },
      font: {
        fontSize: '1.3vw',
      },
    },
    {
      name: 'rightBot-1',
      position: {
        top: 50,
        left: 75,
      },
      size: {
        height: 25,
        width: 25,
      },
      font: {
        fontSize: '1.3vw',
      },
    },
    {
      name: 'rightBot-2',
      position: {
        top: 75,
        left: 75,
      },
      size: {
        height: 25,
        width: 25,
      },
      font: {
        fontSize: '1.3vw',
      },
    },
    {
      name: 'left',
      position: {
        top: 12.5,
        left: 0,
      },
      size: {
        height: 75,
        width: 75,
      },
      font: {
        fontSize: '1.3vw',
      },
    },
  ],
  RIGHT_SCREEN: [
    {
      name: 'left',
      position: {
        top: 19,
        left: -12.4,
      },
      size: {
        height: 62,
        width: 62,
      },
      z: {
        zIndex: -1,
      },
      font: {
        fontSize: '1.3vw',
      },
    },
    {
      name: 'right',
      position: {
        top: 19,
        right: 0,
      },
      size: {
        height: 62,
        width: 64,
      },
      font: {
        fontSize: '1.3vw',
      },
    },
  ],
  RIGHT_SCREEN_TWO_SPEAKERS: [
    {
      name: 'leftTop',
      position: {
        top: 15,
        left: -2.5,
      },
      size: {
        height: 35,
        width: 35,
      },
      font: {
        fontSize: '1.3vw',
      },
    },
    {
      name: 'leftBot',
      position: {
        top: 50,
        left: -2.5,
      },
      size: {
        height: 35,
        width: 35,
      },
      font: {
        fontSize: '1.3vw',
      },
    },
    {
      name: 'right',
      position: {
        top: 15,
        left: 30,
      },
      size: {
        height: 70,
        width: 70,
      },
      font: {
        fontSize: '1.3vw',
      },
    },
  ],
  RIGHT_SCREEN_THREE_SPEAKERS: [
    {
      name: 'leftTop',
      position: {
        top: 12.5,
        left: 0,
      },
      size: {
        height: 25,
        width: 25,
      },
      font: {
        fontSize: '1.3vw',
      },
    },
    {
      name: 'leftCenter',
      position: {
        top: 37.5,
        left: 0,
      },
      size: {
        height: 25,
        width: 25,
      },
      font: {
        fontSize: '1.3vw',
      },
    },
    {
      name: 'leftBot',
      position: {
        top: 62.5,
        left: 0,
      },
      size: {
        height: 25,
        width: 25,
      },
      font: {
        fontSize: '1.3vw',
      },
    },
    {
      name: 'right',
      position: {
        top: 12.5,
        left: 25,
      },
      size: {
        height: 75,
        width: 75,
      },
      font: {
        fontSize: '1.3vw',
      },
    },
  ],
  RIGHT_SCREEN_FOUR_SPEAKERS: [
    {
      name: 'leftTop-1',
      position: {
        top: 0,
        left: 0,
      },
      size: {
        height: 25,
        width: 25,
      },
      font: {
        fontSize: '1.4vw',
      },
    },
    {
      name: 'leftTop-2',
      position: {
        top: 25,
        left: 0,
      },
      size: {
        height: 25,
        width: 25,
      },
      font: {
        fontSize: '1.4vw',
      },
    },
    {
      name: 'leftBot-1',
      position: {
        top: 50,
        left: 0,
      },
      size: {
        height: 25,
        width: 25,
      },
      font: {
        fontSize: '1.4vw',
      },
    },
    {
      name: 'leftBot-2',
      position: {
        top: 75,
        left: 0,
      },
      size: {
        height: 25,
        width: 25,
      },
      font: {
        fontSize: '1.4vw',
      },
    },
    {
      name: 'right',
      position: {
        top: 12.5,
        left: 25,
      },
      size: {
        height: 70,
        width: 75,
      },
      font: {
        fontSize: '1.4vw',
      },
    },
  ],
  SIDE_BY_SIDE: [
    {
      name: 'left',
      position: {
        top: 25,
        left: 0,
      },
      size: {
        height: 50,
        width: 50,
      },
      background: {
        background: '#000000',
      },
      font: {
        fontSize: '1.3vw',
      },
    },
    {
      name: 'right',
      position: {
        top: 25,
        left: 50,
      },
      size: {
        height: 50,
        width: 50,
      },
      background: {
        background: '#000000',
      },
      font: {
        fontSize: '1.3vw',
      },
    },
  ],
  THREE_SPEAKERS: [
    {
      name: 'leftTop',
      position: {
        top: 0,
        left: 0,
      },
      size: {
        height: 50,
        width: 50,
      },
      font: {
        fontSize: '1.3vw',
      },
    },
    {
      name: 'rightTop',
      position: {
        top: 0,
        left: 50,
      },
      size: {
        height: 50,
        width: 50,
      },
      font: {
        fontSize: '1.3vw',
      },
    },
    {
      name: 'centerBot',
      position: {
        top: 50,
        left: 25,
      },
      size: {
        height: 50,
        width: 50,
      },
      font: {
        fontSize: '1.3vw',
      },
    },
  ],
  FOUR_PARTS: [
    {
      name: 'leftTop',
      position: {
        top: 0,
        left: 0,
      },
      size: {
        height: 50,
        width: 50,
      },
      font: {
        fontSize: '1.3vw',
      },
    },
    {
      name: 'rightTop',
      position: {
        top: 0,
        left: 50,
      },
      size: {
        height: 50,
        width: 50,
      },
      font: {
        fontSize: '1.3vw',
      },
    },
    {
      name: 'leftBot',
      position: {
        top: 50,
        left: 0,
      },
      size: {
        height: 50,
        width: 50,
      },
      font: {
        fontSize: '1.3vw',
      },
    },
    {
      name: 'rightBot',
      position: {
        top: 50,
        left: 50,
      },
      size: {
        height: 50,
        width: 50,
      },
      font: {
        fontSize: '1.3vw',
      },
    },
  ],
}

export default SCENE_LAYOUTS
