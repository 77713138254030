<template lang="pug">
div.v-scene-timer
  div.v-scene-timer-content
    div.v-scene-timer-counter
      span {{ minutes }}
      span :
      span {{ seconds }}
    div.v-scene-timer-description {{ timer.description }}
</template>

<script>
import BannerTimer from '@/models/banner-timer'

export default {
  name: 'VSceneTimer',
  props: {
    timer: {
      type: BannerTimer,
      required: true,
    },
  },
  data: () => ({
    minutes: 0,
    seconds: 0,
    time: 0,
    worker: null,
  }),
  computed: {
    isReverseTimer() {
      return this.timer.options.reverse
    },
  },
  mounted() {
    const minutes = this.timer.options.minutes
    const seconds = this.timer.options.seconds

    this.time = minutes * 60 + seconds

    if (this.isReverseTimer) {
      this.minutes = minutes
      this.seconds = seconds

      this.minutes = this.minutes + ''
      if (this.minutes.length === 1) {
        this.minutes = '0' + this.minutes
      }

      this.seconds = this.seconds + ''
      if (this.seconds.length === 1) {
        this.seconds = '0' + this.seconds
      }
    } else {
      this.minutes = '00'
      this.seconds = '00'
    }

    this.createWorker()
  },
  beforeUnmount() {
    if (this.worker) {
      this.worker.postMessage({
        action: 'stop',
      })
    }
    this.worker = null
  },
  methods: {
    createWorker() {
      const workerCode = this.getWorkerCode()
      const workerBlob = new Blob([workerCode], {
        type: 'text/javascript',
      })
      this.worker = new Worker(URL.createObjectURL(workerBlob))

      this.worker.postMessage({
        action: 'start',
        time: this.time,
      })

      this.worker.onmessage = (event) => {
        const seconds = event.data

        this.minutes = Math.floor(seconds / 60)
        this.seconds = seconds - this.minutes * 60

        this.minutes = this.minutes + ''
        if (this.minutes.length === 1) {
          this.minutes = '0' + this.minutes
        }

        this.seconds = this.seconds + ''
        if (this.seconds.length === 1) {
          this.seconds = '0' + this.seconds
        }

        this.$emit('tick-timer', {
          minutes: this.minutes,
          seconds: this.seconds,
        })
      }
    },
    getWorkerCode() {
      return this.isReverseTimer
        ? this.getReverseTimerWorkerCode()
        : this.getRegularTimerWorkerCode()
    },
    getReverseTimerWorkerCode() {
      return `
        let countdown = 0
        let interval = null
        self.onmessage = function (event) {
          console.log(event.data)
          if (event.data.action === "start") {
            countdown = event.data.time
            postMessage(countdown);
            interval = setInterval(() => {
              countdown--;
              postMessage(countdown);
              if (countdown === 0) {
                clearInterval(interval);
              }
            }, 1000);
          }
          if (event.data.action === "stop") {
            clearInterval(interval);
          }
        }
      `
    },
    getRegularTimerWorkerCode() {
      return `
        let time = 0
        let maxTime = 0
        let interval = null
        self.onmessage = function (event) {
          console.log(event.data)
          if (event.data.action === "start") {
            maxTime = event.data.time
            postMessage(time);
            interval = setInterval(() => {
              time++;
              postMessage(time);
              if (time === maxTime) {
                clearInterval(interval);
              }
            }, 1000);
          }
          if (event.data.action === "stop") {
            clearInterval(interval);
          }
        }
      `
    },
  },
}
</script>

<style lang="scss" scoped>
.v-scene-timer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    width: 100%;
    height: 100%;
  }

  &-counter {
    font-size: 256px;
    font-weight: 700;
    line-height: 256px;

    & span {
      &:after {
        content: ' ';
      }
    }
  }

  &-description {
    max-width: 80%;
    margin-top: 20px;
    font-size: 1.5rem;
  }
}
</style>
