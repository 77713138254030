<template lang="pug">
Chart.proofix-interactive__chart.interactive(
  :style='{ "background-image": "url(" + getInteractiveBg + ")", background: getInteractiveBg }'
  :options='chartOption'
  :chartData='chartData.data'
  ref='chart'
)
</template>

<script>
import Chart from '@/components/widgets/chart/chart'
import axios from 'axios'
import resourceMain from '/config'
import { mapGetters } from 'vuex'

export default {
  name: 'InteractiveChart',
  components: {
    Chart,
  },
  props: {
    interactive: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      interactiveSettings: '',
      interval: null,
      chartData: {
        data: {
          labels: [],
          datasets: [
            {
              data: [],
              backgroundColor: [],
              borderColor: 'transparent',
              barThickness: 50,
              datalabels: {
                anchor: 'end',
                align: 'end',
                formatter: function (value, context) {
                  return (
                    context.chart.data.datasets[0].data[context.dataIndex] +
                    ' %'
                  )
                },
              },
            },
          ],
        },
      },
      chartOption: {
        title: {
          display: true,
          text: [],
          align: 'center',
          fontSize: 20,
        },
        plugins: {
          title: {
            display: true,
            text: 'Chart Title',
          },
        },
        indexAxis: 'y',
        elements: {
          rectangle: {
            borderWidth: 2,
            borderSkipped: 'top',
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,
        animation: {
          duration: 0,
        },
        legend: {
          display: false,
        },
        tooltips: {
          enabled: true,
        },
        scales: {
          xAxes: [
            {
              display: true,
              gridLines: {
                display: false,
                color: 'rgba(200, 200, 200, 0.2)',
                zeroLineColor: 'rgba(200, 200, 200, 0.2)',
              },
              scaleLabel: {
                display: true,
              },
              ticks: {
                fontColor: '#6e6b7b',
                beginAtZero: true,
                callback: function (value) {
                  return value + '%'
                },
              },
            },
          ],
          yAxes: [
            {
              display: true,
              gridLines: {
                display: false,
                color: 'rgba(200, 200, 200, 0.2)',
                zeroLineColor: 'rgba(200, 200, 200, 0.2)',
              },
              scaleLabel: {
                display: false,
              },
              ticks: {
                min: 0,
                fontColor: '#6e6b7b',
                callback: function (value) {
                  let wordLength = 0
                  let arrayIndex = 0
                  let newArr = []
                  value.split('').forEach((elem, index) => {
                    if (elem === ' ' && index - wordLength > 10) {
                      wordLength = index
                      arrayIndex++
                    } else if (newArr[arrayIndex]) {
                      newArr[arrayIndex] = newArr[arrayIndex].toString() + elem
                    } else {
                      newArr[arrayIndex] = elem
                    }
                  })
                  return newArr
                },
                fontSize: 18,
              },
            },
          ],
        },
      },
    }
  },
  computed: {
    ...mapGetters('branding', ['getPrimaryColor', 'getInteractiveBg']),
    ...mapGetters('interactives', ['getInteractivesBackground']),
  },
  async mounted() {
    console.log(resourceMain.API.main.root)
    await axios
      .get(
        `${resourceMain.API.main.root}polls?room_id=${this.interactive.room_id}&interactive_setting_id=${this.interactive.id}`,
        {
          headers: {
            Authorization:
              'Bearer Be39JWWga+P2okT3h312IL3FzzLAavENlgbeYAoUskE=',
          },
        }
      )
      .then(async (res) => {
        res.data.data.list[0].answers.filter((item) => {
          this.chartData.data.datasets[0].backgroundColor.push(
            item.director_settings.bar_color
          )
        })
        let question = res.data.data.list[0].question
        for (let i = 0; i < question.length; i += 60) {
          this.chartOption.title.text.push(question.slice(i, i + 60))
        }
        this.chartOption.title.fontSize = this.calculateFontSize(
          question.length
        )
        // this.$emit('name-interactive', res.data.data.list[0].question)
        this.$store.commit(
          'branding/INTERACTIVE_TITLE',
          res.data.data.list[0].question
        )
        await this.updateChart(res.data.data.list[0].id)
        this.$refs.chart.renderChart(
          this.$refs.chart.chartData,
          this.$refs.chart.options
        )
      })
  },
  methods: {
    calculateFontSize(textLength) {
      const maxFontSize = 46
      const minFontSize = 20
      const maxLength = 200
      if (textLength >= maxLength) {
        return minFontSize
      }
      return (
        maxFontSize - ((maxFontSize - minFontSize) * textLength) / maxLength
      )
    },
    async updateChart(polId) {
      const {
        data: { data },
      } = await axios.get(
        `${resourceMain.API.main.root}polls/results/${polId}`,
        {
          headers: {
            Authorization:
              'Bearer Be39JWWga+P2okT3h312IL3FzzLAavENlgbeYAoUskE=',
          },
        }
      )
      let total = 0
      data.forEach((elem) => {
        total = total + elem.members.length
      })
      let sortedData = data.sort((a, b) => a.order - b.order)
      this.chartData.data.labels = sortedData.map((elem) => elem.answer)
      this.chartData.data.datasets[0].data = data.map((elem) => {
        return ((100 / total) * elem.members.length).toFixed(2)
      })
      // this.chartData.data.datasets[0].backgroundColor = this.getPrimaryColor
      this.$refs.chart.renderChart(
        this.$refs.chart.chartData,
        this.$refs.chart.options
      )
      setTimeout(() => {
        this.updateChart(polId)
      }, 2000)
    },
  },
}
</script>

<style lang="scss">
.proofix-interactive__chart {
  width: 100%;
  height: 100%;
  position: relative;
  // background-color: #fff;
}
.interactive {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
}
</style>
