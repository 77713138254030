<template lang="pug">
div.video-container(
  ref='container'
  :class='{ "show-bg": participantCameraMuted(participant) && participantScreenShareMuted(participant)}'
)
  video.video(ref='video')
  audio(ref='audio')
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'VVideoContainer',
  props: {
    participant: {
      type: [Array, undefined],
      required: false,
      default: undefined,
    },
    source: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isPlaying: undefined,
      audioEl: new Audio(),
    }
  },
  computed: {
    ...mapGetters('track', [
      'participantCameraMuted',
      'participantScreenShareMuted',
      'getParticipantTrack',
    ]),
    participantAudioStream() {
      let audioSource = this.source.filter((source) => {
        return source !== 'camera' && source !== 'screen_share'
      })
      return this.getParticipantStream(
        this.participant?.[1].identity,
        audioSource
      )
    },
    participantVideoStream() {
      let videoSource = this.source.filter((source) => {
        return source !== 'microphone' && source !== 'screen_share_audio'
      })
      if (
        videoSource.includes('camera') &&
        videoSource.includes('screen_share') &&
        this.participant?.[1]?.isScreenShareEnabled
      ) {
        const newSource = videoSource.filter((source) => source !== 'camera')
        return this.getParticipantStream(
          this.participant?.[1].identity,
          newSource
        )
      }

      if (
        videoSource.includes('camera') &&
        videoSource.includes('screen_share') &&
        !this.participant?.[1]?.isScreenShareEnabled
      ) {
        const newSource = videoSource.filter(
          (source) => source !== 'screen_share'
        )
        return this.getParticipantStream(
          this.participant?.[1].identity,
          newSource
        )
      }
      return this.getParticipantStream(
        this.participant?.[1].identity,
        videoSource
      )
    },
    participantVideoTrack() {
      return this.participantVideoStream
        .getTracks()
        .find((track) => track.kind === 'video')
    },
    participantAudioTrack() {
      return this.participantAudioStream
        .getTracks()
        .find((track) => track.kind === 'audio')
    },
  },
  watch: {
    // participant(newVal) {
    //   if (newVal?.length === 2) {
    //     this.setStream()
    //   }
    // },
    participantVideoTrack(newVal, oldVal) {
      if (oldVal === undefined && newVal) {
        this.setStream()
      }
      if (newVal && oldVal?.id !== newVal.id) {
        this.setStream()
      }
    },
    participantAudioTrack(newVal, oldVal) {
      if (oldVal === undefined && newVal) {
        this.setStream()
      }
      if (newVal && oldVal?.id !== newVal.id) {
        this.setStream()
      }
    },
    source(newSource, oldSource) {
      if (newSource.length !== oldSource.length) {
        this.setStream()
      }
    },
    participantTracks() {
      this.setStream()
    },
  },
  async mounted() {
    if (this.participant?.length === 2) {
      this.initStream()
    }
  },
  methods: {
    async initStream() {
      this.setStream()
    },
    playVideo() {
      console.log(true)
      console.log(this.$refs.video)
      this.$refs.video.play()
    },
    async setStream() {
      this.$refs.video.srcObject = this.participantVideoStream
      this.$refs.audio.srcObject = this.participantAudioStream

      this.isPlayingVideo = this.$refs.video.play()
      if (this.isPlayingVideo !== undefined) {
        this.isPlayingVideo
          .then(() => {
            this.$refs.video.play()
          })
          .catch(() => {})
      }
      this.isPlayingAudio = this.$refs.audio.play()
      if (this.isPlayingAudio !== undefined) {
        this.isPlayingAudio
          .then(() => {
            this.$refs.audio.play()
          })
          .catch(() => {})
      }
    },
    getParticipantStream(participantIdentity, sources) {
      const tracks = []
      sources?.forEach((source) => {
        const track = this.getParticipantTrack(
          participantIdentity,
          source
        )?.track?.mediaStream?.getTracks()[0]
        if (
          track?.kind === 'video' &&
          this.participantCameraMuted(this.participant) &&
          this.participant[1].identity !== '0000-0000'
        ) {
          return
        }
        if (track) {
          tracks.push(track)
        }
      })
      return new MediaStream(tracks)
    },
  },
}
</script>

<style lang="scss">
.video-container {
  height: 100%;
  width: 100%;
  position: relative;
  aspect-ratio: 16 / 9;
  &.show-bg {
    background: url('~@/images/account_circle.png') center center no-repeat;
    background-size: cover;
    video {
      visibility: hidden;
    }
  }
  video {
    width: 100%;
    height: 100%;
  }
  .video {
    object-fit: cover;
  }
}
</style>
